export const CDN_URL = 'https://storage.googleapis.com/ai-web-assets'

export const ONELINK_STAGE = 'https://palupstage.onelink.me/4Ah8/8xvmz6vs'
export const ONELINK_BETA = 'https://palupbeta.onelink.me/RrMs/fexxobfj'
export const ONELINK_PROD = 'https://palup.onelink.me/wrme/5uh4k2o4'

export const ONELINK_URL = (env: string) => {
  switch (env) {
    case 'beta':
      return ONELINK_BETA
    case 'prod':
      return ONELINK_PROD
    case 'stage':
    default:
      return ONELINK_STAGE
  }
}

export const NO_PHOTO_PATH = CDN_URL + '/human/images/no_photo.svg' // if path is public can use /image...

export const BINDING_GOOGLE_ACCOUNT_URL = '/api/auth/bind/google'

export const AUTHORIZATION_GOOGLE_ACCOUNT_URL = '/api/auth/callback/google'

export const AUTHORIZATION_DROPBOX_ACCOUNT_URL = '/api/auth/callback/dropbox'

export const AUTHORIZATION_ONE_DRIVE_ACCOUNT_URL =
  '/api/auth/callback/one-drive'

// --- Other config ---
export const CONSENT_COOKIE_NAME = 'pca'
export const USER_COOKIE_NAME = '__playsee_user'

export const AUTH_COOKIE_NAME = 'session'

export const REFRESH_COOKIE_NAME = 'refresh'

export const USER_INFO_COOKIE_NAME = 'user'

export const GOOGLE_TOKEN_COOKIE_NAME = 'google_session'

export const DROPBOX_TOKEN_COOKIE_NAME = 'dropbox_session'

export const ONE_DRIVE_TOKEN_COOKIE_NAME = 'one_drive_session'

export const DROPBOX_REFRESH_TOKEN_COOKIE_NAME = 'one_drive_refresh_session'

export const IS_EMBED_COOKIE_NAME = 'isEmbed'
export const TRACKER_COOKIE_NAME = 'tracker'

export const USER_INFO_TEMPLATE = '{guest}|{show_age_confirmation}|{user_id}'

export const BIRTHDAY_FORMAT = 'MM/DD/YYYY'

export const DEFAULT_ERROR_MESSAGE = 'Response Error'

// TODO: translation
export const LINKS = [
  {
    href: '/pals',
    title: 'Pals',
    tabIcon:
      'i-ps-user_s_big group-hover:i-ps-user_f_big group-data-[active="true"]:i-ps-user_f_big',
  },
  {
    href: '/chat-with-pals',
    title: 'Discover',
    tabIcon:
      'i-ps-search_s_big group-hover:i-ps-search_f_big group-data-[active="true"]:i-ps-search_f_big',
  },
  {
    href: '/account',
    title: 'Account',
    tabIcon:
      'i-ps-user_rou_s_big group-hover:i-ps-user_rou_f_big group-data-[active="true"]:i-ps-user_rou_f_big',
  },
] as const

export const OS_THRESHOLDS: Record<string, number> = {
  iOS: 16,
  Android: 8,
}

export const BROWSER = {
  Chrome: 'Chrome',
  'Mobile Chrome': 'Mobile Chrome',
  Safari: 'Safari',
  'Mobile Safari': 'Mobile Safari',
  Firefox: 'Firefox',
  'Mobile Firefox': 'Mobile Firefox',
}

export const BROWSER_THRESHOLDS: Record<string, number> = {
  [BROWSER.Chrome]: 109,
  [BROWSER['Mobile Chrome']]: 109,
  [BROWSER.Safari]: 16.6,
  [BROWSER['Mobile Safari']]: 16.6,
}
