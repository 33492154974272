import { TRACKER_EVENTS } from '@paladise/tracker/constants/event'
import { HASH_KEY } from '@paladise/utils/hooks/useHash'
import { locales } from './i18n'
import { ROUTE_PATH } from './routerPath'

export const PRODUCT = '@paladise/consumer'

export const BINDING_GOOGLE_ACCOUNT_URL = '/api/auth/bind/google'

export const AUTHORIZATION_GOOGLE_ACCOUNT_URL = '/api/auth/callback/google'

export const CAREERS_POSITIONS = {
  SENIOR_FRONTEND_SOFTWARE_ENGINEER: 'Senior Frontend Software Engineer',
  SENIOR_BACKEND_SOFTWARE_ENGINEER: 'Senior Backend Software Engineer',
  SOFTWARE_QUALITY_ENGINEER: 'Software Quality Engineer',
  PRODUCT_DESIGNER: 'Product Designer',
  PRODUCT_MANAGER: 'Product Manager',
  SALES_DEVELOPMENT_SPECIALIST: 'Sales Development Specialist',
  PARTNER_SPECIALIST: 'Partner Specialist',
  LEGAL_COUNSEL: 'Legal Counsel',
} as const
export const CAREERS_URL = 'https://jobs.ashbyhq.com/palup'
export const CAREERS_OVERVIEW_URL_BASE = 'https://jobs.ashbyhq.com/palup'
export const CAREERS_POSITION_URL = {
  [CAREERS_POSITIONS.SENIOR_FRONTEND_SOFTWARE_ENGINEER]: `${CAREERS_OVERVIEW_URL_BASE}/b62b4f6c-1194-43de-b9b6-7f9d6e1f9a02`,
  [CAREERS_POSITIONS.SENIOR_BACKEND_SOFTWARE_ENGINEER]: `${CAREERS_OVERVIEW_URL_BASE}/b4e0569a-0b69-4e0b-a9d0-d50c17d0b9c7`,
  [CAREERS_POSITIONS.SOFTWARE_QUALITY_ENGINEER]: `${CAREERS_OVERVIEW_URL_BASE}/3114fe42-20e9-464e-b50b-c19a063d8ac5`,
  [CAREERS_POSITIONS.PRODUCT_DESIGNER]: `${CAREERS_OVERVIEW_URL_BASE}/2edb0f8b-da92-4076-b76b-c4235dbc30ee`,
  [CAREERS_POSITIONS.PRODUCT_MANAGER]: `${CAREERS_OVERVIEW_URL_BASE}/d2cbf96c-8fb2-4144-b66a-6fbe33cb43d8`,
  [CAREERS_POSITIONS.SALES_DEVELOPMENT_SPECIALIST]: `${CAREERS_OVERVIEW_URL_BASE}/2913ec31-c713-45fc-8921-72ddfc2f9d1a`,
  [CAREERS_POSITIONS.PARTNER_SPECIALIST]: `${CAREERS_OVERVIEW_URL_BASE}/996660b7-2a7a-4fb4-bcf0-bcb04d5095be`,
  [CAREERS_POSITIONS.LEGAL_COUNSEL]: `${CAREERS_OVERVIEW_URL_BASE}/4f14fb97-6db3-44bf-a81b-2716c1b5310c`,
}
export const ONELINK_URL = 'https://palup.onelink.me/dMHZ/q9rz4cw8'
export const APP_STORE_URL$$ = 'https://apps.apple.com/app/id6483759941'
export const PLAY_STORE_URL$$ =
  'https://play.google.com/store/apps/details?id=com.myth.midgard'

export const sidebarLinks = (t: (key: keyof IntlMessages) => string) =>
  [
    {
      href: ROUTE_PATH.SIDEBAR.FEED,
      title: t('home'),
      tabIcon: 'i-ps-user_s_big group-data-[active="true"]:i-ps-user_f_big',
    },
    {
      href: ROUTE_PATH.SIDEBAR.DISCOVER,
      title: t('discover'),
      tabIcon: 'i-ps-search_s_big group-data-[active="true"]:i-ps-search_f_big',
    },
    {
      href: ROUTE_PATH.SIDEBAR.MESSAGE,
      title: t('messages'),
      tabIcon: 'i-ps-mail_s_big group-data-[active="true"]:i-ps-mail_f_big',
    },
    {
      href: `#${HASH_KEY.DOWNLOAD_APP}`,
      title: t('favorites'),
      tabIcon: 'i-ps-star_s_big group-data-[active="true"]:i-ps-star_f_big',
    },
    {
      href: `#${HASH_KEY.DOWNLOAD_APP}`,
      title: t('created_by_you'),
      tabIcon: 'i-ps-create_s_big group-data-[active="true"]:i-ps-create_f_big',
    },
    {
      href: `#${HASH_KEY.DOWNLOAD_APP}`,
      title: t('notifications'),
      tabIcon:
        'i-ps-notification_s_big group-data-[active="true"]:i-ps-notification_f_big',
    },
    {
      href: ROUTE_PATH.PLANS,
      title: 'Official',
      tabIcon: 'i-ps-palup',
    },
  ] as const

export const TERMS_NAME = {
  PALUP_RULES: 'palup_rules',
  PRIVACY_NOTICE: 'privacy_notice',
  USER_TERMS_OF_SERVICE: 'user_terms_of_service',
  BOOST_ADVERTISING_TERMS: 'boost_advertising_terms',
  ADVERTISING_POLICIES: 'advertising_policies',
} as const

export const TERMS_MAP = {
  'palup-rules': {
    api: TERMS_NAME.PALUP_RULES,
    title: 'Palup Rules',
  },
  'privacy-policy': {
    api: TERMS_NAME.PRIVACY_NOTICE,
    title: 'Privacy Policy',
  },
  'user-terms-of-service': {
    api: TERMS_NAME.USER_TERMS_OF_SERVICE,
    title: 'Terms of Service',
  },
  advertising: {
    api: TERMS_NAME.BOOST_ADVERTISING_TERMS,
    title: 'Advertising',
  },
  'advertising-policies': {
    api: TERMS_NAME.ADVERTISING_POLICIES,
    title: 'Advertising Policies',
  },
} as const

export const CREDIT_THRESHOLD = 100

export const DEFAULT_LEAVE_URL = 'https://www.google.com/'

export const MINIMUM_AGE = 18

export const EmailVerifyTimeKey = `email-verify-time`

export const supportEMail = `support@palup.ai`
export const careersEMail = `recruiting@palup.ai`
export const mediaQueriesEmail = 'press@palup.ai'
export const investorQueriesEmail = 'investors@palup.ai'

export const X_URL = 'https://x.com/palup_ai/'
export const DISCORD_URL = 'https://discord.gg/BR7Gynde'
export const INSTAGRAM_URL = 'https://www.instagram.com/palup.ai/ '
export const LINKEDIN_URL = 'https://www.linkedin.com/company/palup-ai/'

export const MQTT_CREDIT_TOPIC = {
  CREDIT_UPDATE: 'credit_update',
  AVATAR_EDIT: 'avatar_edit',
}

export const REDIRECT_PARAMS = 'redirect'

const HELP_URLS = Object.values(ROUTE_PATH.HELP)
const TERMS_URLS = Object.values(ROUTE_PATH.TERMS)

export const GUEST_ALLOWED_URLS = [
  ROUTE_PATH.ROOT,
  ROUTE_PATH.DEMO_PAL,
  ROUTE_PATH.VERIFY_EMAIL,
  ROUTE_PATH.ENTRY.ABOUT,
  ROUTE_PATH.ENTRY.PRODUCT,
  ROUTE_PATH.CAREERS,
  ROUTE_PATH.ENTRY.CONTACT.INDEX,
  ROUTE_PATH.ENTRY.CONTACT.COMPLETED,
  ROUTE_PATH.PLANS,
  ROUTE_PATH.DOWNLOAD,
  ROUTE_PATH.SIDEBAR.DISCOVER,
  ROUTE_PATH.VERIFY_EMAIL,
  `${ROUTE_PATH.SIDEBAR.FEED}/*`,
  `${ROUTE_PATH.SIDEBAR.MESSAGE}`,
  `${ROUTE_PATH.AUTH}/*`,
  `${ROUTE_PATH.RESET_PASSWORD}/*`,
  ...HELP_URLS,
  ...TERMS_URLS,
  ...locales.map(locale => `/${locale}`),
  ...locales.map(locale => `/${locale}${ROUTE_PATH.ENTRY.ABOUT}`),
]

export const Country = {
  'United States': 'US',
  Canada: 'CA',
  Afghanistan: 'AF',
  Albania: 'AL',
  Algeria: 'DZ',
  'American Samoa': 'AS',
  Andorra: 'AD',
  Angola: 'AO',
  Anguilla: 'AI',
  Antarctica: 'AQ',
  'Antigua & Barbuda': 'AG',
  Argentina: 'AR',
  Armenia: 'AM',
  Aruba: 'AW',
  Australia: 'AU',
  Austria: 'AT',
  Azerbaijan: 'AZ',
  Bahamas: 'BS',
  Bahrain: 'BH',
  Bangladesh: 'BD',
  Barbados: 'BB',
  Belarus: 'BY',
  Belgium: 'BE',
  Belize: 'BZ',
  Benin: 'BJ',
  Bermuda: 'BM',
  Bhutan: 'BT',
  Bolivia: 'BO',
  'Bosnia & Herzegovina': 'BA',
  Botswana: 'BW',
  'Bouvet Island': 'BV',
  Brazil: 'BR',
  'British Indian Ocean Territory': 'IO',
  'British Virgin Islands': 'VG',
  Brunei: 'BN',
  Bulgaria: 'BG',
  'Burkina Faso': 'BF',
  Burundi: 'BI',
  Cambodia: 'KH',
  Cameroon: 'CM',
  'Cape Verde': 'CV',
  'Caribbean Netherlands': 'BQ',
  'Cayman Islands': 'KY',
  'Central African Republic': 'CF',
  Chad: 'TD',
  Chile: 'CL',
  'Christmas Island': 'CX',
  'Cocos (Keeling) Islands': 'CC',
  Colombia: 'CO',
  Comoros: 'KM',
  'Congo - Brazzaville': 'CG',
  'Congo - Kinshasa': 'CD',
  'Cook Islands': 'CK',
  'Costa Rica': 'CR',
  Croatia: 'HR',
  Curaçao: 'CW',
  Cyprus: 'CY',
  Czechia: 'CZ',
  'Côte d’Ivoire': 'CI',
  Denmark: 'DK',
  Djibouti: 'DJ',
  Dominica: 'DM',
  'Dominican Republic': 'DO',
  Ecuador: 'EC',
  Egypt: 'EG',
  'El Salvador': 'SV',
  'Equatorial Guinea': 'GQ',
  Eritrea: 'ER',
  Estonia: 'EE',
  Eswatini: 'SZ',
  Ethiopia: 'ET',
  'Falkland Islands': 'FK',
  'Faroe Islands': 'FO',
  Fiji: 'FJ',
  Finland: 'FI',
  France: 'FR',
  'French Guiana': 'GF',
  'French Polynesia': 'PF',
  'French Southern Territories': 'TF',
  Gabon: 'GA',
  Gambia: 'GM',
  Georgia: 'GE',
  Germany: 'DE',
  Ghana: 'GH',
  Gibraltar: 'GI',
  Greece: 'GR',
  Greenland: 'GL',
  Grenada: 'GD',
  Guadeloupe: 'GP',
  Guam: 'GU',
  Guatemala: 'GT',
  Guernsey: 'GG',
  Guinea: 'GN',
  'Guinea-Bissau': 'GW',
  Guyana: 'GY',
  Haiti: 'HT',
  'Heard & McDonald Islands': 'HM',
  Honduras: 'HN',
  'Hong Kong SAR China': 'HK',
  Hungary: 'HU',
  Iceland: 'IS',
  India: 'IN',
  Indonesia: 'ID',
  Iran: 'IR',
  Iraq: 'IQ',
  Ireland: 'IE',
  'Isle of Man': 'IM',
  Israel: 'IL',
  Italy: 'IT',
  Jamaica: 'JM',
  Japan: 'JP',
  Jersey: 'JE',
  Jordan: 'JO',
  Kazakhstan: 'KZ',
  Kenya: 'KE',
  Kiribati: 'KI',
  Kuwait: 'KW',
  Kyrgyzstan: 'KG',
  Laos: 'LA',
  Latvia: 'LV',
  Lebanon: 'LB',
  Lesotho: 'LS',
  Liberia: 'LR',
  Libya: 'LY',
  Liechtenstein: 'LI',
  Lithuania: 'LT',
  Luxembourg: 'LU',
  'Macao SAR China': 'MO',
  Madagascar: 'MG',
  Malawi: 'MW',
  Malaysia: 'MY',
  Maldives: 'MV',
  Mali: 'ML',
  Malta: 'MT',
  'Marshall Islands': 'MH',
  Martinique: 'MQ',
  Mauritania: 'MR',
  Mauritius: 'MU',
  Mayotte: 'YT',
  Mexico: 'MX',
  Micronesia: 'FM',
  Moldova: 'MD',
  Monaco: 'MC',
  Mongolia: 'MN',
  Montenegro: 'ME',
  Montserrat: 'MS',
  Morocco: 'MA',
  Mozambique: 'MZ',
  Myanmar: 'MM',
  Namibia: 'NA',
  Nauru: 'NR',
  Nepal: 'NP',
  Netherlands: 'NL',
  'New Caledonia': 'NC',
  'New Zealand': 'NZ',
  Nicaragua: 'NI',
  Niger: 'NE',
  Nigeria: 'NG',
  Niue: 'NU',
  'Norfolk Island': 'NF',
  'North Macedonia': 'MK',
  'Northern Mariana Islands': 'MP',
  Norway: 'NO',
  Oman: 'OM',
  Pakistan: 'PK',
  Palau: 'PW',
  'Palestinian Territories': 'PS',
  Panama: 'PA',
  'Papua New Guinea': 'PG',
  Paraguay: 'PY',
  Peru: 'PE',
  Philippines: 'PH',
  'Pitcairn Islands': 'PN',
  Poland: 'PL',
  Portugal: 'PT',
  'Puerto Rico': 'PR',
  Qatar: 'QA',
  Romania: 'RO',
  Rwanda: 'RW',
  Réunion: 'RE',
  Samoa: 'WS',
  'San Marino': 'SM',
  'Saudi Arabia': 'SA',
  Senegal: 'SN',
  Serbia: 'RS',
  Seychelles: 'SC',
  'Sierra Leone': 'SL',
  Singapore: 'SG',
  'Sint Maarten': 'SX',
  Slovakia: 'SK',
  Slovenia: 'SI',
  'Solomon Islands': 'SB',
  Somalia: 'SO',
  'South Africa': 'ZA',
  'South Georgia & South Sandwich Islands': 'GS',
  'South Korea': 'KR',
  'South Sudan': 'SS',
  Spain: 'ES',
  'Sri Lanka': 'LK',
  'St. Barthélemy': 'BL',
  'St. Helena': 'SH',
  'St. Kitts & Nevis': 'KN',
  'St. Lucia': 'LC',
  'St. Martin': 'MF',
  'St. Pierre & Miquelon': 'PM',
  'St. Vincent & Grenadines': 'VC',
  Sudan: 'SD',
  Suriname: 'SR',
  'Svalbard & Jan Mayen': 'SJ',
  Sweden: 'SE',
  Switzerland: 'CH',
  Syria: 'SY',
  'São Tomé & Príncipe': 'ST',
  Taiwan: 'TW',
  Tajikistan: 'TJ',
  Tanzania: 'TZ',
  Thailand: 'TH',
  'Timor-Leste': 'TL',
  Togo: 'TG',
  Tokelau: 'TK',
  Tonga: 'TO',
  'Trinidad & Tobago': 'TT',
  Tunisia: 'TN',
  Turkey: 'TR',
  'Turks & Caicos Islands': 'TC',
  Tuvalu: 'TV',
  'U.S. Outlying Islands': 'UM',
  'U.S. Virgin Islands': 'VI',
  Uganda: 'UG',
  Ukraine: 'UA',
  'United Arab Emirates': 'AE',
  'United Kingdom': 'GB',
  Uruguay: 'UY',
  Vanuatu: 'VU',
  'Vatican City': 'VA',
  Venezuela: 'VE',
  Vietnam: 'VN',
  'Wallis & Futuna': 'WF',
  'Western Sahara': 'EH',
  Yemen: 'YE',
  Zambia: 'ZM',
  Zimbabwe: 'ZW',
  'Åland Islands': 'AX',
} as const

type LinkItem = {
  text: string
  href: string
  trackerEventName: string
}

export const footerRows = (
  t: (key: keyof IntlMessages) => string,
  baseUrl: {
    consumer: string
    enterprise: string
  },
) => ({
  row1: (): LinkItem[] => [
    {
      text: t('home'),
      href: baseUrl.consumer,
      trackerEventName: '',
    },
    {
      text: t('about'),
      href: `${baseUrl.consumer}/${ROUTE_PATH.ENTRY.ABOUT}`,
      trackerEventName: TRACKER_EVENTS.text_about_click,
    },
    // {
    //   text: 'PalUp Official',
    //   href: baseUrl.enterprise,
    //   trackerEventName: '',
    // },
    {
      text: t('common_product'),
      href: `${baseUrl.consumer}/${ROUTE_PATH.ENTRY.PRODUCT}`,
      trackerEventName: '',
    },
  ],
  row2: (): LinkItem[] => [
    {
      text: t('help_center'),
      href: `${baseUrl.consumer}/${ROUTE_PATH.HELP.INDEX}`,
      trackerEventName: TRACKER_EVENTS.text_helpcenter_click,
    },
    {
      text: t('common_careers'),
      href: `${baseUrl.consumer}/${ROUTE_PATH.CAREERS}`,
      trackerEventName: '',
    },
    {
      text: t('common.Contact'),
      href: `${baseUrl.consumer}/${ROUTE_PATH.ENTRY.CONTACT.INDEX}`,
      trackerEventName: '',
    },
  ],
  legal: (): LinkItem[] => [
    {
      text: t('user_terms_of_service'),
      href: `${baseUrl.consumer}/${ROUTE_PATH.TERMS.USER_TERMS_OF_SERVICE}`,
      trackerEventName: TRACKER_EVENTS.text_termsofservice_click,
    },
    {
      text: t('privacy_cookies'),
      href: `${baseUrl.consumer}/${ROUTE_PATH.TERMS.PRIVACY_POLICY}`,
      trackerEventName: TRACKER_EVENTS.text_privacypolicy_click,
    },
  ],
})

export type MenuParent = {
  text: string
  children: MenuChild[]
  trackerEventName?: string
  fullPage?: boolean
}

export type MenuChild = {
  text: string
  href: string
  trackerEventName?: string
}

export type MenuItem = MenuChild | MenuParent

export const entryMobileMenu = (
  t: (key: keyof IntlMessages) => string,
  baseUrl: {
    consumer: string
    enterprise: string
  },
): MenuItem[] => [
  {
    text: t('common.go_to_pallup'),
    href: `${baseUrl.consumer}/${ROUTE_PATH.SIDEBAR.FEED}`,
  },
  {
    text: t('about'),
    href: `${baseUrl.consumer}/${ROUTE_PATH.ENTRY.ABOUT}`,
  },
  // {
  //   text: 'PalUp Official',
  //   href: baseUrl.enterprise,
  // },
  {
    text: t('common_product'),
    href: `${baseUrl.consumer}/${ROUTE_PATH.ENTRY.PRODUCT}`,
  },
  {
    text: t('common_legal'),
    children: [
      {
        text: t('user_terms_of_service'),
        href: `${baseUrl.consumer}/${ROUTE_PATH.TERMS.USER_TERMS_OF_SERVICE}`,
      },
      {
        text: t('privacy_cookies'),
        href: `${baseUrl.consumer}/${ROUTE_PATH.TERMS.PRIVACY_POLICY}`,
      },
      {
        text: t('terms_advertising_terms_service'),
        href: `${baseUrl.consumer}/${ROUTE_PATH.TERMS.ADVERTISING}`,
      },
      {
        text: t('terms_advertising_policies'),
        href: `${baseUrl.consumer}/${ROUTE_PATH.TERMS.ADVERTISING_POLICIES}`,
      },
      {
        text: t('ethical_rules'),
        href: `${baseUrl.consumer}/${ROUTE_PATH.TERMS.PALUP_RULES}`,
      },
    ],
  },
  {
    text: t('help_center'),
    children: [
      {
        text: t('help_getting_started'),
        href: `${baseUrl.consumer}/${ROUTE_PATH.HELP.GETTING_STARTED}`,
      },
      {
        text: t('help_account'),
        href: `${baseUrl.consumer}/${ROUTE_PATH.HELP.ACCOUNT}`,
      },
      {
        text: t('help_billing_subscriptions'),
        href: `${baseUrl.consumer}/${ROUTE_PATH.HELP.BILLING_AND_SUBSCRIPTIONS}`,
      },
      {
        text: t('help_privacy_safety'),
        href: `${baseUrl.consumer}/${ROUTE_PATH.HELP.PRIVACY_SAFETY}`,
      },
      {
        text: t('help_report'),
        href: `${baseUrl.consumer}/${ROUTE_PATH.HELP.REPORT}`,
      },
      {
        text: t('help_intellectual_property'),
        href: `${baseUrl.consumer}/${ROUTE_PATH.HELP.INTELLECTUAL_PROPERTY}`,
      },
    ],
  },
  {
    text: t('common_careers'),
    href: `${baseUrl.consumer}/${ROUTE_PATH.CAREERS}`,
  },
  {
    text: t('contact_us'),
    href: `${baseUrl.consumer}/${ROUTE_PATH.ENTRY.CONTACT.INDEX}`,
  },
]

export const appMobileMenu = (
  t: (key: keyof IntlMessages) => string,
  config: { guest: boolean },
): MenuItem[] => {
  const menuItems: MenuItem[] = [
    {
      text: t('favorites'),
      href: '#',
    },
    {
      text: t('created_by_you'),
      href: '#',
    },
    {
      text: t('notifications'),
      href: '#',
    },
    {
      text: t('official'),
      href: ROUTE_PATH.PLANS,
    },
  ]

  if (!config.guest) {
    menuItems.push({
      text: t('common.account'),
      href: ROUTE_PATH.ACCOUNT.INDEX,
    })
  }

  menuItems.push({
    text: t('about'),
    fullPage: true,
    children: [
      {
        text: t('contact_form_company'),
        href: ROUTE_PATH.ENTRY.ABOUT,
      },
      {
        text: t('help_center'),
        href: ROUTE_PATH.HELP.INDEX,
      },
      {
        text: t('user_rules'),
        href: ROUTE_PATH.TERMS.PALUP_RULES,
      },
      {
        text: t('user_terms_of_service'),
        href: ROUTE_PATH.TERMS.USER_TERMS_OF_SERVICE,
      },
      {
        text: t('common.cookie_policy'),
        href: ROUTE_PATH.TERMS.PRIVACY_POLICY,
      },
    ],
  })

  return menuItems
}
