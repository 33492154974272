export type Env = 'local' | 'stage' | 'beta' | 'master'

export const ENVIRONMENT = process.env.ENVIRONMENT as Env
export const JWT_SECRET = process.env.JWT_SECRET as string
export const COMMIT_HASH = process.env.COMMIT_HASH as string
export const STRIPE_PUBLIC_KEY = process.env.STRIPE_PUBLIC_KEY as Env
export const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID as string
export const GOOGLE_CLIENT_SECRET = process.env.GOOGLE_CLIENT_SECRET as string
export const FACEBOOK_CLIENT_ID = process.env.FACEBOOK_CLIENT_ID as string
export const FACEBOOK_CLIENT_SECRET = process.env
  .FACEBOOK_CLIENT_SECRET as string
export const DROPBOX_CLIENT_ID = process.env.DROPBOX_CLIENT_ID as string
export const DROPBOX_CLIENT_SECRET = process.env.DROPBOX_CLIENT_SECRET as string
export const GOOGLE_API_KEY = process.env.GOOGLE_API_KEY as string
export const ONE_DRIVE_CLIENT_ID = process.env.ONE_DRIVE_CLIENT_ID as string
export const ONE_DRIVE_CLIENT_SECRET = process.env
  .ONE_DRIVE_CLIENT_SECRET as string
export const GTM_ID = process.env.GTM_ID as string
export const GA_ID = process.env.GA_ID as string

export const CONSUMER_BASE_URL = process.env.CONSUMER_BASE_URL as string
export const ENTERPRISE_BASE_URL = process.env.ENTERPRISE_BASE_URL as string

// API
// ASGARD
// USER
// MEDIA
// SESSION
// HERMES
// CHAT
export const API_ASGARD = process.env.API_ASGARD as string
export const API_USER = process.env.API_USER as string
export const API_MEDIA = process.env.API_MEDIA as string
export const API_SESSION = process.env.API_SESSION as string
export const API_HERMES = process.env.API_HERMES as string
export const API_CHAT = process.env.API_CHAT as string
export const API_REPORT = process.env.API_REPORT as string
export const API_BOOST = process.env.API_BOOST as string
export const API_GW = process.env.API_GW as string

export const SERVER_ASGARD = process.env.SERVER_ASGARD as string
export const SERVER_USER = process.env.SERVER_USER as string
export const SERVER_MEDIA = process.env.SERVER_MEDIA as string
export const SERVER_SESSION = process.env.SERVER_SESSION as string
export const SERVER_HERMES = process.env.SERVER_HERMES as string
export const SERVER_CHAT = process.env.SERVER_CHAT as string
export const SERVER_REPORT = process.env.SERVER_REPORT as string
export const SERVER_BOOST = process.env.SERVER_BOOST as string
export const SERVER_GW = process.env.SERVER_GW as string

// HOST
// MQTT
// NOTIFY
// LIVEKIT
// TRACKER
export const HOST_MQTT = process.env.HOST_MQTT as string
export const HOST_NOTIFY = process.env.HOST_NOTIFY as string
export const HOST_TRACKER = process.env.HOST_TRACKER as string
