'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { TextField } from '@paladise/ui/components/TextField'
import { Button } from '@paladise/ui/components/ui/button'
import { useTranslations } from 'next-intl'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import useToast from 'utils/hooks/useToast'
import { handleLogin } from '../actions/auth'
import { AuthSchema } from '../schema'
import { type AuthSchemaType } from '../types'
import { usePopupStore } from 'store/popup-store'

interface Prop {
  email: string
}

function LoginForm({ email }: Prop) {
  const t = useTranslations()
  const [isLoading, setIsLoading] = useState(false)
  const { showErrorToast, showSuccessToast } = useToast()
  const setAuthPopup = usePopupStore.use.setAuthPopup()

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<AuthSchemaType>({
    resolver: zodResolver(AuthSchema),
    defaultValues: { email: email, password: '' },
    mode: 'onChange',
  })

  const fieldItem = [
    {
      name: 'email',
      label: t('email'),
      placeholder: t('enter_email'),
      isInvalid: !!errors.email,
      errorMessage:
        errors.email?.message && t(errors.email?.message as 'required'),
      defaultValue: email,
      disable: true,
    },
    {
      name: 'password',
      label: t('password'),
      placeholder: t('six_to_thirty_chars_limitation'),
      isInvalid: !!errors.password,
      errorMessage:
        errors.password?.message && t(errors.password?.message as 'required'),
    },
  ]

  const handleSubmitLogin = async (data: AuthSchemaType) => {
    setIsLoading(true)
    try {
      const userId = await handleLogin(data)
      if (!userId) throw new Error('handleLogin Failed')
      setAuthPopup(false)
    } catch (error) {
      console.log(error)
      showErrorToast({ description: 'Login Failed' })
    }
    setIsLoading(false)
  }

  return (
    <div className="min768:w-[300px] mx-auto h-full w-full">
      <p className="text-label-l1 text-title mb-2">{t('log_in_for_palup')}</p>
      <form
        className="text-callout text-label-l1 flex flex-col space-y-4 pt-6"
        onSubmit={handleSubmit(handleSubmitLogin)}
      >
        {fieldItem.map(item => (
          <TextField
            required
            {...register(item.name as 'email' | 'password', { required: true })}
            type={item.name === 'password' ? 'password' : 'text'}
            key={item.name}
            label={item.label}
            isInvalid={item.isInvalid}
            errorMessage={item.errorMessage}
            placeholder={item.placeholder}
            defaultValue={item.defaultValue}
            disabled={item.disable}
            containerProps={{ className: 'space-y-[6px]' }}
            className="placeholder:text-label-l3"
          />
        ))}

        <Button type="submit" disabled={isLoading} loading={isLoading}>
          {t('login')}
        </Button>
      </form>
    </div>
  )
}

export default LoginForm
