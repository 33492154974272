import { getUser } from 'api/user'
import { type UserInfo } from 'features/account/types'
import { getPaymentMethods } from 'features/payment/actions/get-payment-methods'
import { type IMethod, type ISubscription } from 'features/payment/type'
import { clientFetch } from 'lib/fetch/client'
import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { session } from './server-context/static'
import { createSelectors } from '@paladise/utils/zustand-selector'
import { getUserSubscription } from 'api/purchase'
import { type ChatHistory } from 'features/chat/type'
import { getChatHistory } from 'api/chat'

export type AccountState = {
  userInfo: { data: UserInfo | null; loading: boolean }
  subscription: { data: ISubscription | null; loading: boolean }
  paymentMethods: {
    data: IMethod[] | null
    default: IMethod | null
    loading: boolean
  }
  chatHistory: { data: ChatHistory[] | null; loading: boolean }
}

export type AccountActions = {
  syncUserInfo: () => void
  syncPaymentMethod: () => void
  syncSubscription: () => void
  syncChatHistory: () => void
}

export type AccountStore = AccountState & AccountActions

export const useAccountStoreBase = create<AccountStore>()(
  subscribeWithSelector((set, get) => ({
    userInfo: { data: null, loading: false },
    subscription: { data: null, loading: false },
    paymentMethods: { data: null, default: null, loading: false },
    chatHistory: { data: null, loading: false },
    syncPaymentMethod: async () => {
      try {
        const method = get().paymentMethods
        set({ paymentMethods: { ...method, loading: true } })
        const data = await getPaymentMethods()
        if (!data) throw new Error('Get Payment Methods Failed')
        const defaultMethod = data.payment_methods.find(
          method => method.is_default,
        )
        set({
          paymentMethods: {
            data: data.payment_methods,
            default: defaultMethod || null,
            loading: false,
          },
        })
        return true
      } catch (error) {
        set({ paymentMethods: { data: null, default: null, loading: false } })
        return false
      }
    },
    syncUserInfo: async () => {
      try {
        const info = get().userInfo
        set({ userInfo: { data: info.data, loading: true } })
        const data = await getUser(clientFetch)
        if (!data.data) throw new Error('Get User Profile Failed')
        set({ userInfo: { data: data.data, loading: false } })
        return true
      } catch (error) {
        set({ userInfo: { data: null, loading: true } })
        return false
      }
    },
    syncSubscription: async () => {
      try {
        const data = get().subscription
        set({ subscription: { ...data, loading: true } })
        const res = await getUserSubscription(clientFetch)
        if (!res) throw new Error('Get User subscription Failed')
        set({ subscription: { data: res, loading: false } })
        return true
      } catch (error) {
        set({ subscription: { data: null, loading: true } })
        return false
      }
    },
    syncChatHistory: async () => {
      const data = get().chatHistory
      set({ chatHistory: { ...data, loading: true } })
      const res = await getChatHistory(clientFetch, session.userId)
      if (!res) throw new Error('Get Chat History Failed')
      set({ chatHistory: { data: res.data, loading: false } })
      return true
    },
  })),
)

export const useAccountStore = createSelectors(useAccountStoreBase)
